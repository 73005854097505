<template>
  <div>
    <div v-if="isAllowPage" class="pt-5 pb-3 w-50 m-auto">
      <security-banner></security-banner>
    </div>

    <div v-else class="service-times-presentations template-2">
      <header class="page-header container mx-lg">
        <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
      </header>
      <!-- / Page Header -->
      <page-body class="mx-lg">
        <section class="section-1">
          <div class="bg-block p-4">
            <header class="mb-2">
              <h4>{{ translations.tcPaymentCenter }}</h4>
            </header>
            <div class="d-flex row ml-1 mb-2 details2 ">
              <h5>
                {{
      paymentInfo.isAutoPaySetup && !paymentInfo.isPayDuesLinkSupposeToBeVisible
        ? `${translations.tcMembershipDuesAutopay} ${paymentFrequency}`
        : 'MEMBERSHIP DUES'
    }}
              </h5>
            </div>

            <template>
              <div class="d-flex row mb-4 details2"
                v-if="paymentInfo.isAutoPaySetup && !paymentInfo.isPayDuesLinkSupposeToBeVisible">
                <div class="col">
                  <div class="detail">
                    <b-button variant="primary" @click="handleAutoPayClick" class>
                      {{ translations.tcEditPayment }}
                    </b-button>

                    <b-button variant="primary" class="mt-4" v-if="isPaymentFrequencyAnnual && isLoginUserAutoOn"
                      @click="userMembershipAutoPayOffHandler">
                      TURN OFF AUTO-PAY</b-button>
                  </div>
                </div>
                <div class="col">
                  <div class="detail">
                    <h4>{{ translations.tcPersonalInformation }}</h4>
                    <template v-if="paymentInfo.expireDateField === '' || paymentInfo.expireDateField === null">
                      <span class="paymentBold">{{ paymentInfo.addressee }}</span>
                      {{ translations.tcPaymentType }}:
                      <span class="paymentBold">{{ translations.tcACH }}</span>
                      {{ translations.tcAccountNumber }}:
                      <span class="paymentBold">{{ paymentInfo.lastFour }}</span>
                      {{ translations.tcRoutingNumber }}:
                      <span class="paymentBold"
                        v-if="!!paymentInfo.routingNumberField && paymentInfo.routingNumberField.length > 4">*****{{
      paymentInfo.routingNumberField.substring(paymentInfo.routingNumberField.length - 4,
        paymentInfo.routingNumberField.length)
    }}</span>
                      <!-- <span class="paymentBold" v-else>{{ paymentInfo.routingNumberField }}</span> -->
                    </template>
                    <template v-else>

                      <div style="width: 420px">
                        <table>
                          <colgroup>
                            <col style="width: 120px;" />
                            <col />
                          </colgroup>
                          <tr>
                            <td>Cardholder:</td>
                            <td><span class="paymentBold">{{ paymentInfo.addressee }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcPaymentType }}:</td>
                            <td><span class="paymentBold">{{ translations.tcCreditCard }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcAccountNumber }}:</td>
                            <td><span class="paymentBold">***********{{ paymentInfo.lastFour }}</span></td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcBillingAddress }}:</td>
                            <td><span class="paymentBold">{{ paymentInfo.street }}</span>
                              <span class="paymentBold">{{ paymentInfo.city }}, {{ paymentInfo.state }} {{
      paymentInfo.zip }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ translations.tcExpirationDate }}:</td>
                            <td><span class="paymentBold">{{ paymentInfo.expireDateField }}</span></td>
                          </tr>
                        </table>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <div class="d-flex row ml-1 mb-2 details2"
              v-if="!paymentInfo.isAutoPaySetup && !paymentInfo.isPayDuesLinkSupposeToBeVisible">
              <h4>{{ translations.tcNoAutoPayAssociatedWithAccount }}</h4>
            </div>
            <b-button
              v-if="!paymentInfo.isAutoPaySetup && !paymentInfo.isPayDuesLinkSupposeToBeVisible && paymentInfo.mbrPendingDropDate === null && paymentInfo.paidFlag"
              variant="primary" @click="handleAutoPayClick" class="ml-2 mb-4 details2">Set Up Auto Payment
            </b-button>

            <div
              v-if="paymentInfo.isPayDuesLinkSupposeToBeVisible || (paymentInfo.mbrPendingDropDate !== null && !paymentInfo.paidFlag)"
              class="d-flex mb-4 details2">
              <b-button v-if="!paymentInfo.mbrInstallpay" variant="primary mr-4" @click="handleOneTimePaymentLinkClick"
                class="ml-2 w-25">Pay Now</b-button>
              <b-button v-if="paymentInfo.mbrPendingDropDate === null" variant="primary-reverse"
                @click="handleOneTimeRenewalMonthlyLinkClick">
                Monthly Installments</b-button>
            </div>
          </div>
        </section>
      </page-body>
      <page-footer class="mx-lg mt-5">
        <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
      </page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import constantData from '@/json/data.json'
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import SecurityBanner from '@/components/SecurityBanner'

export default {
  name: 'member-payment-center',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      toggleMe: true,
      backJumpObj: {
        link: '/programs/mb/member-profile',
        location: ''
      },
      PaymentMethod_ACH_Guid: '3cde1523-989d-4264-bf2b-1f04e3752498',
      PaymentMethod_ACH_Name: 'ACH CyberSource',
      PaymentMethod_CCD_Guid: '8d85d8d1-d6ee-4e6e-a6e4-27212c8a1043',
      PaymentMethod_CCD_Name: 'Credit Card CyberSource',
      paymentFrequency: '',
      isLoginUserAutoSetup: null
    }
  },
  methods: {
    ...mapActions({
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadPaymentInformation: 'membership/loadPaymentInformation',
      setIsIndividualProfileLifeTime: 'membership/setIsIndividualProfileLifeTime',
      setLoadingStatus: 'menu/setLoadingStatus',
      turnOffMembershipAnnualAutoPay: 'membership/turnOffMembershipAnnualAutoPay'
    }),

    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getViewTranslations(),
        await this.loadIndividualProfile(),
        await this.loadPaymentInformation(this.individualProfile.ind_key),
        await this.getComponentTranslations('back-jump'),
        await this.setIsIndividualProfileLifeTime(),
        (this.backJumpObj.location = this.individualProfile.formal_name)
      ]).then(results => {
        const componentTranslations = results[4]
        this.$set(this.translations, 'components', componentTranslations)
        this.paymentFrequency = this.paymentInfo.frequency || ''
        this.isLoginUserAutoSetup = Object.hasOwn(this.paymentInfo, 'isAutoPaySetup') && this.paymentInfo.isAutoPaySetup
        this.setLoadingStatus(false)
      })
    },
    formatDate(thisDate) {
      if (!thisDate) {
        return ''
      }
      return thisDate.substring(0, 10)
    },
    async handleAutoPayClick() {
      this.$router.push({ path: '/programs/mb/autopay-form/link' })
    },
    async handleOneTimePaymentLinkClick() {
      this.$router.push({ path: '/programs/mb/member-dues/link' })
    },
    async handleOneTimeRenewalMonthlyLinkClick() {
      this.$router.push({ path: '/programs/mb/member-renewal/monthly/' })
    },
    async userMembershipAutoPayOffHandler() {
      const showSuccessMessage = async () => {
        return this.$swal({
          icon: 'success',
          text: 'You have successfully turned off annual auto-pay.'
        })
      }

      const showErrorMessage = async () => {
        this.$swal({
          icon: 'error',
          text: 'Something went wrong. Please try again.'
        })
      }

      this.$swal({
        html: `
        <div class="confirm-modal-inner">
          <img src="${require('@/assets/svgs/warning.svg')}" />
          <h3>Are you sure?</h3>
          <p>Do you want to turn off the annual automatic renewal for your payment method?</p>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(async result => {
        if (result.isConfirmed) {
          this.setLoadingStatus(true)
          const response = await this.turnOffMembershipAnnualAutoPay({
            cst_key: this.userIndKey
          })
          if (response) {
            showSuccessMessage().then(async () => {
              await this.pageLoad()
            })
          } else {
            showErrorMessage()
          }
          this.setLoadingStatus(false)
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      individualProfile: 'membership/individualProfile',
      isIndividualProfileMemberLifetime: 'membership/isIndividualProfileMemberLifetime',
      paymentInfo: 'membership/paymentInfo',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey'
    }),
    isAllowPage() {
      return this.isIndividualProfileMemberLifetime
    },
    isPaymentFrequencyAnnual() {
      const ANNUAL_FREQ = 'Annually'
      return this.paymentFrequency === ANNUAL_FREQ
    },
    isLoginUserAutoOn() {
      return this.isLoginUserAutoSetup === true
    }
  },

  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ path: '/programs/mb/membership/link' })
    }
    await this.pageLoad()
  },
  components: {
    BackJump: BackJump,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    ToggleArrow: ToggleArrow,
    SecurityBanner
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.unenroll {
  color: #ee0000;
  width: 300px;
  font-weight: 600;
}

.paymentBold {
  font-weight: bold;
}

.btn-primary-reverse {
  background-color: #fff;
  color: #000;
  border-color: #000;
  border: 5px solid #003946 !important;
}

.btn.btn-primary-reverse:hover {
  background-color: #003946;
  color: #fff;
}

.service-times-presentations {
  .page-header h1 {
    margin-bottom: 50px;
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .section-1 {
      .ml-4 {
        margin-left: 0 !important;
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.details2 {
  margin-bottom: 50px;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  h5 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .col {
    flex: 0 0 33.3333%;
    width: 33.3333%;

    @include breakpoint(sm) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .detail {
    font-size: 15px;
    margin-bottom: 10px;

    strong,
    span {
      display: block;
      color: $gray;
      letter-spacing: 1px;
    }

    strong {
      padding-right: 10px;
      text-transform: uppercase;
      font-weight: 900;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    td {
      vertical-align: top;
    }

    span {
      flex: 1 1 auto;
      align-self: flex-end;
    }

    &.full-detail {
      display: block;
    }
  }
}
</style>
